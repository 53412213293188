import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {first} from "rxjs/operators";
import {Blog} from "@core/models/blog.model";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private readonly API_URL = environment.sqlApi;
  constructor(
    private http: HttpClient
  ) {
    console.log(this.API_URL);
  }

  public getActualAllPosts() {
    return this.http.get('https://maradona.askipo.com/api.stage/blog/unapproved').pipe(first()).toPromise() as Promise<Blog[]>;
  }

  public getUnapprovedPosts() {
    return this.http.get('https://maradona.askipo.com/api.stage/blog/unapproved').pipe(first()).toPromise() as Promise<Blog[]>;
  }

  public getAllPosts() {
    return this.http.get(this.API_URL + 'blog').pipe(first()).toPromise() as Promise<Blog[]>;
  }

  public getPost(id: number) {
    return this.http.get(this.API_URL + 'blog/' + id).pipe(first()).toPromise() as Promise<Blog>;
  }

  public async addPost(data, image: File) {

    const add = await this.http.post(this.API_URL + 'blog', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(first()).toPromise();

    await this.addImage(image, add['id'].toString());

    return add;
  }

  public deletePost(id: number) {
    return this.http.delete(this.API_URL + 'blog/' + id)
      .pipe(first()).toPromise();
  }

  public async updatePost(data, image) {
    console.log(data);
    data = {
      ...data,
      nutritionistId: null,
      approved: true,
      enabled: true
    };

    const add = await this.http.put((this.API_URL + 'blog/' + data.Id), data).pipe(first()).toPromise();

    if (image !== null) {
      await this.addImage(image, data.Id.toString());
    }
    return add;
  }

  private addImage(image: File, id: string) {
    const formData = new FormData();
    formData.append('Id', id);
    formData.append('Image', image);

    return this.http.post(this.API_URL + 'blog/postfile', formData).pipe(first()).toPromise();
  }
}
